
// import firebase from 'firebase/app'
import gql from "graphql-tag"
import debounce from "lodash.debounce"
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
    BAlert,
    BCardText,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BCardBody,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BFormGroup, BFormSelect,
    BLink,
    BInputGroupAppend,
    BBadge,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
} from 'bootstrap-vue'
import store from "@/store"



const GET_CANCELLATIONS = gql`
    query getCancellations (
        $codcfo: String,
        $contrato: Int,
        $cliente_nome: String,
        $offset: Int,
        $limit: Int,
        $order_by: [api_view_portal_corretor_distratos_order_by!]
    ) {
        api_view_portal_corretor_distratos(
            where: {
                _and: [
                    {codcfo: {_eq: $codcfo}},
                    {valor_comissao_nao_integrado: {_gt: 0}},
                    {
                        _or: [
                            {contrato: {_eq: $contrato}},
                            {cliente_nome: {_like: $cliente_nome}}
                        ]
                    }
                ]
            },
            order_by: $order_by,
            offset: $offset,
            limit: $limit
        ) {
            contrato
            data_venda
            data_cancelamento
            cliente_nome
            empreendimento_fantasia
            valor_venda
            valor_recebido
            valor_comissao_total
            valor_comissao_paga
            valor_comissao_prevista
            valor_comissao_nao_integrado
            codcfo
            lote
            quadra
        }
        api_view_portal_corretor_distratos_aggregate(
            where: {
                _and: [
                    {codcfo: {_eq: $codcfo}},
                    {valor_comissao_nao_integrado: {_gt: 0}},
                    {
                        _or: [
                            {contrato: {_eq: $contrato}},
                            {cliente_nome: {_like: $cliente_nome}}
                        ]
                    }
                ]
            },
        ) {
            aggregate {
                count
            }
        }
    }
`;

export default {

    components: {
        BCardText,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BCardBody,
        BCardCode,
        BFormCheckbox,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BFormGroup, BFormSelect,
        BLink,
        BInputGroupAppend,
        BBadge,
        BSpinner,
        BDropdown,
        BDropdownItem,
        BPagination,
        BInputGroup,
        BInputGroupPrepend,
        BAlert,
    },

    data() {
        return {
            cancellations: null,
            loading: false,
            perPage: 10,
            totalRows: 1,
            currentPage: 1,
            pageOptions: [10, 15, 20],
            sortBy: 'data_cancelamento',
            sortDesc: true,
            sortDirection: 'desc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: '',
            },
            fields: [
                {
                    key: 'contrato',
                    label: 'Contrato',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    sortable: true
                },
                {
                    key: 'data_venda',
                    label: 'Venda',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    sortable: true
                },
                {
                    key: 'data_cancelamento',
                    label: 'Distrato',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    sortable: true
                },
                {
                    key: 'cliente_nome',
                    label: 'Cliente',
                    sortable: true
                },
                {
                    key: 'empreendimento_fantasia',
                    label: 'Empreendimento',
                    sortable: false
                },
                {
                    key: 'actions',
                    label: 'Detalhe',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    sortable: false
                },
            ],
        }
    },

    created() {
        if (this.loggedUser) {
            this.getCancellations();
        }
    },

    methods: {

        async getCancellations() {
            this.loading = true;

            this.$apollo.query({
                query: GET_CANCELLATIONS,
                variables: {
                    codcfo: this.loggedUser.codcfo,
                    contrato: parseInt(this.filter) || 0,
                    cliente_nome: '%' + (this.filter || '') + '%',
                    offset: (this.perPage * this.currentPage) - this.perPage,
                    limit: this.perPage,
                    order_by: { [this.sortBy || 'data_cancelamento']: this.sortDesc ? 'desc' : 'asc' }
                },
                fetchPolicy: 'cache-first' //'cache-first ou no-cache'
            })

                .then((data) => {
                    this.loading = false;
                    this.cancellations = data['data']['api_view_portal_corretor_distratos']
                    this.totalRows = data["data"]["api_view_portal_corretor_distratos_aggregate"]["aggregate"]["count"] || 0
                })

                .catch(error => {
                    this.loading = false;
                    this.showToast({
                        title: 'Erro!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: 'Erro ao obter Distratos! ' + error,
                    }, 3000);
                })
        },

        showToast(toast, duration) {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: toast.title,
                    icon: toast.icon,
                    variant: toast.variant,
                    text: toast.text
                },
            },
                {
                    timeout: duration
                });
        },

        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',')
            return 'R$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

        formatDate(dateValue) {
            if (dateValue == "" || dateValue == null) {
                return null;
            }
            if (typeof dateValue != "string") {
                dateValue = String(dateValue);
            }
            let year = dateValue.substr(0, 4)
            let month = dateValue.substr(5, 2)
            let day = dateValue.substr(8, 2)

            return day + '/' + month + '/' + year;
        },

        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },

        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },

        clearFilter() {
            this.filter = ''
            this.filterCancellations()
        },

        filterCancellations: debounce(function () {
            this.currentPage = 1
            this.getCancellations()
        }, 1000),

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    },

    computed: {
        sortOptions() {
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({ text: f.label, value: f.key }))
        },
        loggedUser() {
            if (store.state.user.loggedUser) {
                return store.state.user.loggedUser
            }
            else {
                return null
            }
        },
    },

    watch: {
        currentPage: {
            handler: function () {
                this.getCancellations()
            }
        },
        sortBy: {
            handler: function () {
                this.currentPage = 1
                this.getCancellations()
            }
        },
        sortDesc: {
            handler: function () {
                this.currentPage = 1
                this.getCancellations()
            }
        }
    }
}